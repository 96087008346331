import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"


import img1 from "../images/adoucisseur1.jpg"
import img2 from "../images/adoucisseur2.jpg"
import img3 from "../images/adoucisseur3.jpg"

const AdoucisseurPage  = () => (
    <Layout>
        <SEO title="Adoucisseur d'eau" />
        <section className="section">
            <div className="container">
                <h1 className="title">Adoucisseur d'eau à Mions : Air Water System</h1>
                <h2 className="subtitle">Air Water System, nos installateurs d’adoucisseur d’eau dans le Rhône</h2>
                <p className="content">
                    Un adoucisseur d’eau est l'outil indispensable pour préserver votre peau !
                    <br></br>
                    Les plombiers d’Air Water System à Mions prennent en charge tous vos travaux d’installation et d’entretien de votre adoucisseur d’eau.
                    <br></br>
                    En effet, l’eau distribuée publiquement est bien souvent de mauvaise qualité, et si vous l’utilisez sur le long terme sans l’adoucir, cela s’en ressentira sur votre peau.
                    <br></br>
                    Il s’agit d’un appareil qui utilise une résine qui échange des ions et qui permet de réduire la présence de calcaire dans l’eau.
                    <br></br>
                    Air water System est depuis 10 ans spécialisée dans l'adoucisseur d'eau et la filtration.
                    <br></br>
                    Aujourd'hui notre entreprise a adopté la technologie du futur avec des appareils écologiques puisqu’ils sont très économes en sel et en eau de régénération.
                    <br></br>
                    L'adoucisseur d'eau instantané sans électricité est le plus simple et le plus performant du marché.
                </p>
            </div>
        </section>
        <section className="section">
            <div className="container">
                <div className="content">
                    <div class="columns">
                        <div class="column">
                            <figure class="image">
                                <img alt="© Air Water System" src={img1}></img>
                            </figure>
                        </div>
                        <div class="column">
                            <figure class="image">
                                <img alt="© Air Water System" src={img2}></img>
                            </figure>
                        </div>
                        <div class="column">
                            <figure class="image">
                                <img alt="© Air Water System" src={img3}></img>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="section">
            <div className="container">
                <h1 className="title">Les avantages de l’adoucisseur d’eau</h1>
                <p className="content">
                    Un adoucisseur d’eau présente de nombreux avantages, tels que : 
                    <br></br>
                    <ul>
                        <li>
                            L’obtention d’une eau plus douce permettant d’avoir des tuyaux et appareils électroménager plus longtemps.
                        </li>
                        <li>
                            L’obtention d’eau plus pure qui sera plus efficace pour le nettoyage par exemple.
                        </li>
                    </ul>
                </p>
            </div>
        </section>
        <section className="section">
            <div className="container">
                <p className="content">
                    Pour de plus amples informations concernant l'installation d'adoucisseur d'eau, n’hésitez pas à contacter notre entreprise Air Water System à Mions.
                </p>
            </div>
        </section>
    </Layout>
)

export default AdoucisseurPage;
